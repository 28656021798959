

import { useEffect } from 'react';
import { fetchProfileByUserId, selectData, getDataStatus, resetProfile } from "../store/profileByUser"
import { fetchApplicationsByProfileId, resetApplications, selectApplications, selectApplicationsStatus } from "../store/applications"
import store from "../store"
import { useSelector } from 'react-redux/es/hooks/useSelector'

import { incrementByAmount, resetApllicationStep } from "../store/applicationStep"

const useApplicationSteps = () => {

    const data = useSelector(selectData);
    const status = useSelector(getDataStatus);
    const applications = useSelector(selectApplications);
    const applicationsStatus = useSelector(selectApplicationsStatus);

    const getUser = async (userId) => {
        await store.dispatch(fetchProfileByUserId(userId))
    }

    const getApplications = async (profileId) => {
        await store.dispatch(fetchApplicationsByProfileId(profileId))
    }

    const changeStep = async (step) => {
        await store.dispatch(incrementByAmount(step))
    }

    useEffect(() => {
        changeStep(9)
        const userId = (sessionStorage.getItem("id"))

        if (userId && !data && status === 'idle') {
            changeStep(9)
            getUser(userId)
        }

        if (userId && !data && status !== 'loading' && status !== 'idle') {
            changeStep(0)
        }

        if (data && applicationsStatus === 'idle') {
            changeStep(9)
            getApplications(data.id)

        }
    }, [status])

    useEffect(() => {

        if (data && applicationsStatus !== 'loading' && applicationsStatus !== 'idle') {

            let estado = applications[0]?.estado || null
            if (!applications.length) {
                changeStep(1)
            }

            if (applications.length && (estado === 'approved' || estado === 'risk_in_process' || estado === 'rejected' || estado === 'on_hold_docs')) {
                changeStep(2)
            }

            if (applications.length && estado === 'firma_contrato') {
                changeStep(3)
            }

            if (applications.length && estado === 'pendiente_aprobacion_medico') {
                changeStep(4)
            }
        }

    }, [applicationsStatus])



}

export const clearSteps = () => {
    store.dispatch(resetApllicationStep())
    store.dispatch(resetApplications())
    store.dispatch(resetProfile())
}


export default useApplicationSteps
