/* eslint-disable no-unused-vars */
import "./App.css";
import React, { useState, useEffect, Suspense } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserContext } from "./Sesion/Actions/appContex";
import axios from "axios";
import store from "./store";
import { Provider } from "react-redux";
import AuthStatus from "./Components/AuthStatus";

export default function App() {
  const [value, setValue] = useState("Hello");

  axios.interceptors.request.use(function (config) {
    config.headers["Content-Type"] = "application/json";
    config.headers["Authorization"] =
      "Bearer " + sessionStorage.getItem("token");
    return config;
  });

  useEffect(() => {
    document.title = "Welli";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<span>Loading...</span>}>
        <Router>
          <Provider store={store}>
            <UserContext.Provider value={{ value, setValue }}>
              <AuthStatus>
              </AuthStatus>
            </UserContext.Provider>

          </Provider>
        </Router>
      </Suspense>
    </ThemeProvider>
  );
}
