import { useEffect, useState } from "react";
import { useDebounced } from "./useDebounced";
import { searchProcedures } from "../api/procedures";

const OtherProcedure = {
    "id": "",
    "name": "Otro",
    "speciality_name": "Otro",
}

export const useProceduresDebounced = (input, maxLengthText = 3) => {
    const debouncedValue = useDebounced(input, 800)
    const [loadingProcedures, setLoadingProcedures] = useState(false);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const getProcedures = async () => {

            if (input && input.length > maxLengthText) {
                setOptions([])
                setLoadingProcedures(true)

                const resp = await searchProcedures(input);
                const data = resp.data.data
                data.length ? setOptions(data.concat(OtherProcedure)) : setOptions([OtherProcedure]);
                setLoadingProcedures(false)
            }else{
                setOptions([])
            }

        }
        getProcedures();

    }, [debouncedValue])

    return {loadingProcedures, options}
}