import { AppBar, Button } from '@mui/material';
import React from 'react';

import { useAuth } from '../hooks/useAuth';

const LayoutCreateAccount = ({ children }) => {
  const { logOut } = useAuth();
  const logout = () => {
    logOut()

  };
  return (
    <div>
      <div>
        <AppBar position="static" color='transparent' >
          <Button onClick={() => logout()} color='primary' variant="contained" sx={{ width: 95, height: 40, m: 1, alignSelf: 'end', fontSize: 10 }}>
            <span className="material-icons" style={{ fontSize: 20 }}>logout</span> Salir
          </Button>
        </AppBar>
      </div>
      <main>
        {children}
      </main>

    </div>
  );
};

export default LayoutCreateAccount;
