import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function GenericModal({ title, description, confirm, bodyComponent = null, textButton = 'SALIR', ...props }) {
  const [, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { show } = props;
  // const navigate = useNavigate();


  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={!!show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Lamentamos informarte que no es posible aprobar tu solicitud de
            crédito.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Si necesitas asesoría adicional escribenos a
            servicioalcliente@welli.com.co ó al Whatsapp +57 310-5240287.
          </Typography> */}

          <Typography id="modal-modal-title" variant="h6" component="h2">{title}</Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {description}
          </Typography>
          {bodyComponent && (<Box sx={{ marginTop: '5px' }} >
            {bodyComponent}
          </Box>)}
          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={confirm}
          >
            {textButton}
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
