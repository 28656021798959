import axios from "axios";



const getPlanCoutes = (appId) => {
    return axios.get(`${process.env.REACT_APP_WELLI_API}/api/application/plan_cuotas/${appId}`);
}

export {
    getPlanCoutes
}