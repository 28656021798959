import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";


export const fetchApplicationsByProfileId = createAsyncThunk(
    'applications/fetchByProfileIdStatus',
    async (profileId) => {
       
        const response = await axios
            .get(`${process.env.REACT_APP_WELLI_API}/api/applications/${profileId}`)

        return response.data.data
    }
)


const initialState = {
    applications: [],
    loading: 'idle', //'idle' | 'pending' | 'succeeded' | 'failed'
    error: ''
}


const applicationsSlice = createSlice({
    name: 'applications',
    initialState,
    reducers: {
        resetApplications:(state)=>{
            state.applications = []
            state.loading = 'idle'
            state.error=''
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchApplicationsByProfileId.pending, (state) => {
            state.loading = "loading"
        })
            .addCase(fetchApplicationsByProfileId.fulfilled, (state, action) => {
                state.loading = "succeeded"
                state.applications = action.payload;
            })
            .addCase(fetchApplicationsByProfileId.rejected, (state, action) => {
                state.loading = "failed"
                state.error = action.error.message
                state.applications = [];


            })
    },
})


export const { resetApplications  } = applicationsSlice.actions

export default applicationsSlice.reducer

export const selectApplications = (state) => state.applications.applications
export const selectApplicationsError = (state) => state.applications.error
export const selectApplicationsStatus = (state) => state.applications.loading
