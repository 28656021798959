import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import axios from "axios";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Tooltip from "@mui/material/Tooltip";
import { getDepartments, getMunicipalitiesByDepartment } from "../api/city-api";
import { useEffect } from "react";
import { useState } from "react";
import { getDiffYearsCurrentDate } from "../helpers/form-helpers";
import { DesktopDatePicker } from "@mui/x-date-pickers";

const textoPublicamente = `Servidores públicos y contratistas del estado`;

const textoRegimen = `Sistema al que se puede acceder de manera voluntaria ante la DIAN y el cual unifica el pago de varios impuestos con el fin de simplificar el cumplimiento de las obligaciones tributarias cuando cumplan ciertos requisitos`;
const maxDate = dayjs().subtract(18, 'years');
const minDate = dayjs().subtract(110, 'years');
const idTipos = [
  {
    value: "1",
    label: "Cédula",
  },
];

const idHijos = [
  {
    value: "0",
    label: "Cero",
  },
  {
    value: "1",
    label: "Uno",
  },
  {
    value: "2",
    label: "Dos",
  },
  {
    value: "3",
    label: "Tres o más",
  },
];


const idIngreso = [
  {
    value: "1000000",
    label: "Hasta $1'000.000",
  },
  {
    value: "1500000",
    label: "Hasta $2'000.000",
  },
  {
    value: "2500000",
    label: "Hasta $3'000.000",
  },
  {
    value: "3500000",
    label: "Hasta $4'000.000",
  },
  {
    value: "4500000",
    label: "Hasta $5'000.000",
  },
  {
    value: "5500000",
    label: "Hasta $6'000.000",
  },
  {
    value: "7500000",
    label: "Hasta $10'000.000",
  },
  {
    value: "10000000",
    label: "Hasta $15'000.000",
  },
  {
    value: "12500000",
    label: "Más de 15 Millones",
  },
];

const idEstadoCivil = [
  {
    value: "Soltero",
    label: "Soltero(a)",
  },
  {
    value: "Casado",
    label: "Casado(a)",
  },
  {
    value: "Separado",
    label: "Separado(a)",
  },
  {
    value: "Viudo",
    label: "Viudo(a)",
  },
];


export default function Profile(props) {
  const { formCompleted } = props;
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      departamento: "",
      ciudad: ""
    }
  });
  const [departments, setDepartments] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [fetchingAccount, setFetchingAccount] = React.useState('idle')


  const onSubmit = (data) => {
    setFetchingAccount('pending');

    const body = {
      ...data,
      fecha_nacimiento: dayjs(data.fecha_nacimiento).format("YYYY-MM-DD"),
      publicamente_expuesto: false,
    };

    body.departamento = departments.find(({ codigo_dane }) => codigo_dane === data.departamento).departamento
    delete body.documento2
    axios
      .post(`${process.env.REACT_APP_WELLI_API}/api/profile`, body)
      .then(function (resp) {
        sessionStorage.setItem("profile_id", resp.data.id);
        setFetchingAccount('succeded');

        formCompleted();
      })
      .catch(function (error) {
        //handle error
        setFetchingAccount('reject');
        console.log(error);
      });
  };

  const getMunicipalities = async (event) => {
    const { value } = event.target
    if (!value) return;

    setValue("departamento", event.target.value);
    setValue("ciudad", "",);

    const result = await getMunicipalitiesByDepartment(value)

    if (result.data.municipios) {
      setMunicipalities(result.data.municipios)
    }

  }

  useEffect(() => {
    if (sessionStorage.getItem("registerByUrl")) {
        const registerByUrl = JSON.parse(sessionStorage.getItem("registerByUrl"));
        setValue('nombres', registerByUrl.pattientName);
        setValue('apellidos', registerByUrl.pattientLastName);
    }
    getDepartments().then((resp) => {
      setDepartments(resp.data.departamentos)
    })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  const validateDate = (value) => {
    if (!value) {
      return "La fecha de nacimiento es requerida";
    }
    if (!dayjs(value).isValid()) {
      return "La fecha de nacimiento no es válida (debe ser en formato mm/dd/aaaa)";
    }

    if (getDiffYearsCurrentDate(value).diffYears < 18) {
      return "Debe ser mayor de 18 años";
    }

    return true;
  };


  return (
    <>
      <React.Fragment>
        <Typography variant="h6" align="center" gutterBottom>
          Información a diligenciar
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="outlined-basic"
                label="Primer Nombre"
                name="nombres"
                fullWidth
                variant="standard"
                {...register("nombres", {
                  required: "El primer nombre es requerido",
                })}
                error={Boolean(errors.nombres)}
                helperText={errors.nombres?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Primer Apellido"
                name="apellidos"
                fullWidth
                autoComplete="primer-apellido"
                variant="standard"
                {...register("apellidos", {
                  required: "El primer apellido es requerido",
                })}
                error={Boolean(errors.apellidos)}
                helperText={errors.apellidos?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Tipo Documento"
                name="tipo_documento"
                defaultValue="1"
                fullWidth
                autoComplete="tipo-documento"
                variant="standard"
                {...register("tipo_documento", {
                  required: "El tipo de documento es requerido",
                })}
                error={Boolean(errors.tipo_documento)}
                helperText={errors.tipo_documento?.message}
              >
                {idTipos.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Número Documento"
                name="documento"
                fullWidth
                autoComplete="numero-documento"
                variant="standard"
                {...register("documento", {
                  required: "El número de documento es requerido",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Ingresa datos numéricos",
                  },
                })}
                error={Boolean(errors.documento)}
                helperText={errors.documento?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Confirmar Número Documento"
                name="documento2"
                fullWidth
                autoComplete="numero-documento"
                variant="standard"
                onPaste={e => e.preventDefault() }
                {...register("documento2", {
                  required: "Debes confirmar el número de documento",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Ingresa datos numéricos",
                  },
                  validate: (value) => value === watch('documento') || 'Los números de documentos no coinciden'
                })}
                error={Boolean(errors.documento2)}
                helperText={errors.documento2?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  disableToolbar
                  label="Fecha de nacimiento (mm/dd/aaaa)"
                  name="fecha_nacimiento"
                  fullWidth
                  autoComplete="fecha-nacimiento"
                  views={['year', 'month', 'day']}
                  value={
                    !getValues("fecha_nacimiento")
                      ? null
                      : getValues("fecha_nacimiento")
                  }
                  onChange={(newValue) => {
                    setValue("fecha_nacimiento", newValue, {
                      shouldValidate: true,
                    });
                  }}
                  maxDate={maxDate}
                  minDate={minDate}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        format="MM-DD-YYYY"
                        variant="standard"
                        {...register("fecha_nacimiento", {
                          required: "La fecha de nacimiento es requerida",
                          validate: validateDate
                        })}
                        error={Boolean(errors.fecha_nacimiento)}
                        helperText={errors.fecha_nacimiento?.message}
                      />
                    );
                  }}
                />
              </LocalizationProvider>

            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Departamento Residencia"
                name="departamento"
                fullWidth
                autoComplete="departamento"
                variant="standard"
                {...register("departamento", {
                  required: "El departamento es requerido",
                })}
                error={Boolean(errors.departamento)}
                helperText={errors.departamento?.message}
                inputProps={{
                  value: watch("departamento"),
                }}

                onChange={getMunicipalities}
                SelectProps={{
                  MenuProps: {
                    style: {
                      maxHeight: "350px"
                    }
                  }
                }}
              >
                <MenuItem key="0default" value="">Seleccione un departamento</MenuItem>

                {departments.map((option, i) => (
                  <MenuItem key={i} value={option.codigo_dane}>
                    {option.departamento}
                  </MenuItem>
                ))}

              </TextField>

            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Municipio Residencia"
                name="ciudad"
                fullWidth
                autoComplete="ciudad"
                variant="standard"
                defaultValue=""
                {...register("ciudad", {
                  required: "La dirección residencia es requerida",
                })}
                error={Boolean(errors.ciudad)}
                helperText={errors.ciudad?.message}
                inputProps={{
                  value: watch("ciudad"),
                }}
                SelectProps={{
                  MenuProps: {
                    style: {
                      maxHeight: "350px"
                    }
                  }
                }}
              >
                <MenuItem key={"0municipality"} value="">Seleccione un municipio</MenuItem>

                {municipalities.map((option, i) => (
                  <MenuItem key={i + 'municipality'} value={option.municipio}>
                    {option.municipio}
                  </MenuItem>
                ))}

              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Dirección Residencia"
                name="direccion_vivienda"
                fullWidth
                autoComplete="primer-apellido"
                variant="standard"
                {...register("direccion_vivienda", {
                  required: "La dirección residencia es requerida",
                })}
                error={Boolean(errors.direccion_vivienda)}
                helperText={errors.direccion_vivienda?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Teléfono Contacto Adicional"
                name="telefono_contacto"
                fullWidth
                variant="standard"
                {...register("telefono_contacto", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Ingresa datos numéricos",
                  },
                  maxLength: {
                    value: 10,
                    message: "El número no debe ser mayor de 10 dígtos",
                  },
                  minLength: {
                    value: 10,
                    message: "El número no debe ser menor de 10 dígtos",
                  },
                })}
                error={Boolean(errors.telefono_contacto)}
                helperText={errors.telefono_contacto?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Número Hijos"
                name="numero_hijos"
                defaultValue="0"
                fullWidth
                autoComplete="numero-hijo"
                variant="standard"
                {...register("numero_hijos", {
                  required: "El número de hijos es requerido",
                })}
                error={Boolean(errors.numero_hijos)}
                helperText={errors.numero_hijos?.message}
              >
                {idHijos.map((option) => (

                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>


            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Estado Civil"
                name="estado_civil"
                defaultValue="Soltero"
                fullWidth
                autoComplete="estado-civil"
                variant="standard"
                {...register("estado_civil", {
                  required: "El estado civil es requerido",
                })}
                error={Boolean(errors.estado_civil)}
                helperText={errors.estado_civil?.message}
              >
                {idEstadoCivil.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Actividad Económica"
                name="actividad_economica"
                defaultValue="Empleado"
                fullWidth
                variant="standard"
                {...register("actividad_economica", {
                  required: "La actividad económica es requerida",
                })}
                error={Boolean(errors.actividad_economica)}
                helperText={errors.actividad_economica?.message}
              >
                <MenuItem key="Empleado" value="Empleado">
                  Empleado
                </MenuItem>
                <MenuItem key="Independiente" value="Independiente">
                  Independiente
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Ingreso Mensual Declarado"
                name="ingreso_mensual"
                defaultValue="1500000"
                fullWidth
                autoComplete="ingreso-mensual"
                variant="standard"
                {...register("ingreso_mensual", {
                  required: "El ingreso mensual declarado es requerido",
                })}
                error={Boolean(errors.ingreso_mensual)}
                helperText={errors.ingreso_mensual?.message}
              >
                {idIngreso.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Empresa"
                name="empresa"
                fullWidth
                autoComplete="Empresa"
                variant="standard"
                {...register("empresa", {
                  required: "La empresa es requerida",
                })}
                error={Boolean(errors.empresa)}
                helperText={errors.empresa?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Cargo ó Profesión"
                name="cargo_profesion"
                fullWidth
                autoComplete="Cargo Profesión"
                variant="standard"
                {...register("cargo_profesion", {
                  required: "El cargo ó profesión es requerido",
                })}
                error={Boolean(errors.cargo_profesion)}
                helperText={errors.cargo_profesion?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Antigüedad meses"
                name="antiguedad_meses"
                fullWidth
                autoComplete="Antiguedad"
                variant="standard"
                {...register("antiguedad_meses", {
                  required: "La antiguedad en meses es requerida",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Ingresa datos numéricos",
                  },
                  max: {
                    value: 240,
                    message: "La antigüedad no debe ser mayor de 20 años",
                  },
                })}
                error={Boolean(errors.antiguedad_meses)}
                helperText={errors.antiguedad_meses?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <Tooltip title={textoPublicamente}>
                <FormControlLabel
                  control={
                    <Checkbox color="secondary" name="expuesto" value="true" />
                  }
                  label={
                    <Typography variant="subtitle2">
                      ¿Marque acá si usted es una persona públicamente expuesta?
                    </Typography>
                  }
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Tooltip title={textoRegimen}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="simplificado"
                      value="yes"
                    />
                  }
                  label={
                    <Typography variant="subtitle2">
                      ¿Marque acá si usted pertenece al Régimen simplificado
                      impuestos?
                    </Typography>
                  }
                />
              </Tooltip>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="submit" variant="contained" sx={{ mt: 3, ml: 1 }} disabled={fetchingAccount === 'pending'} >
              {fetchingAccount === 'pending' ? 'Creando Cuenta...' : 'Siguiente'}

            </Button>
          </Box>
          {fetchingAccount == 'rejected' && (<Box sx={{ display: "flex", justifyContent: "center", marginTop: '20px' }}>
            <Typography
              variant="body2"
              color="red"
              align="justify"
            >
              Ah ocorrido un error al crear el perfil
            </Typography>
          </Box>)
          }
        
          {( Object.values(errors).some((value) => value) && touchedFields) && (<Box sx={{ display: "flex", justifyContent: "center", marginTop: '20px' }}>
            <Typography
              variant="body2"
              color="red"
              align="justify"
            >
              Por favor, llene todos los campos para continuar
            </Typography>
          </Box>)
          }

        </Box>
      </React.Fragment>
    </>
  );
}
