import React from 'react'
import { forwardRef } from "react";
import { NumericFormat } from "react-number-format";

export const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref,
  ) {
    const { onChange, ...other } = props;
  
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          const { floatValue } = values;
          onChange({
            target: {
              name: props.name,
              value: floatValue,
            },
          });
        }}
  
        thousandSeparator
        valueIsNumericString
        prefix="$"
        //TODO cambiar comas por puntos y puntos por comas, en caso que haya decimal
      />
    );
  });