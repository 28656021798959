import { createTheme } from '@mui/material/styles';


const theme = createTheme({

    typography: {
        useNextVariants: true
    },
    palette: {
        primary: {
            main: "#fdd835"
        },
        common: {
            white: 'white'
        },
        secondary: {
            main: "#ef6c00"
        },
        grey: {
            main: "#d2dae1ff",
        },

      
        text: {
            light: "#aeaeae",
            textSecondary: "#c9ccd3ff",
            primary:"#fdd835",
            base: "#3b3f4aff",

        }

    },
    spacing: 10
});


export default theme;
