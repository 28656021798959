import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";


export const fetchProfileByUserId = createAsyncThunk(
    'users/fetchByIdStatus',
    async (userId) => {
        const response = await axios
            .get(`${process.env.REACT_APP_WELLI_API}/api/profile/by_user/${userId}`)



        return response.data
    }
)

const initialState = {
    profile: null,
    loading: 'idle',  //'idle' | 'pending' | 'succeeded' | 'failed'
    error: ''
}



const profileByUserSlice = createSlice({
    name: 'profileByUser',
    initialState,
    reducers: {
        resetProfile:(state)=>{
            state.profile = null
            state.loading = 'idle'
            state.error=''
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProfileByUserId.pending, (state) => {
            state.loading = "loading"
        })
            .addCase(fetchProfileByUserId.fulfilled, (state, action) => {
                state.loading = "succeeded"
                state.profile = action.payload;
            })
            .addCase(fetchProfileByUserId.rejected, (state, action) => {
                state.loading = "failed"
                state.error = action.error.message
            })
    },
})


// Action creators are generated for each case reducer function
export const { resetProfile } = profileByUserSlice.actions

export default profileByUserSlice.reducer

export const selectData = (state) => state.profileByUser.profile
export const getDataError = (state) => state.profileByUser.error
export const getDataStatus = (state) => state.profileByUser.loading
