import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoutes from "../ProtectedRoutes";
import { useAuth, useRefreshToken } from "../hooks/useAuth";
import Application from "./Application";
import Confirm from "./Confirm";
import CreateAccount from "./CreateAccount";
import Home from "./Home";
import Newlogin from "./Newlogin";
import ValidationAccount from "./ValidationAccount";
import { routes } from "../routes/routes";

const AuthStatus = () => {
  const { loginStatus, validateLogin } = useAuth();

  useRefreshToken();
  useEffect(() => {
    validateLogin();
  }, []);

  if (loginStatus == "iddle") {
    return <>Loading...</>;
  }

  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="create_account" exact element={<CreateAccount />}></Route>
      <Route path="val_account" exact element={<ValidationAccount />}></Route>

      <Route path="newlogin" exact element={<Newlogin />}></Route>

      <Route element={<ProtectedRoutes createProfile/>}>
        <Route path="profileform" exact element={<Application />} />
        <Route path="applast" exact element={<Confirm />} />

      </Route>

      <Route element={<ProtectedRoutes createProfile={false}/>}>

        {routes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
};

export default AuthStatus;
