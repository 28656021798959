import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
}  from '@mui/material';


const SessionTimeoutDialog = ({ open, countdown, onLogout, onContinue }) => {

  return (
    <Dialog
      open={open}
      style={{padding:10}}
    >
      <DialogTitle color="black">
        Hemos detectado inactividad
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="text.secondary" >
          La sesión actual está a punto de caducar en {" "}
          <span style={{ color: 'red' }}>{countdown}</span> Segundos.
        </Typography>
        <Typography variant="body2" color="text.secondary">{`¿Quieres continuar con la sesión?`}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onLogout}
          variant="contained"
          color='primary' sx={{ height: 40, m: 1, fontSize: 10 }} >
          Cerrar Sesión
        </Button>
        <Button
          onClick={onContinue}
          variant="contained"
          color="info"
          sx={{ height: 40, m: 1,  fontSize: 10 }} >
          Continuar en la sesión
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default SessionTimeoutDialog;
