export const currencyFormat = (number, showDecimals=true) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = "$1,";

    if (number) {
        let arr = number.toString().split(".");
        arr[0] = arr[0].replace(exp, rep);
        if (showDecimals) {
            return arr[1] ? arr.join(".") : arr[0];
        } else {
            return arr[0];
        }
    } else {
        return "";
    }
};