import { lazy } from 'react';
import { NoLazy } from '../features/dashboard/pages/NoLazy';

const LazyLayout = lazy(() => import(/* webpackChunkName: "LazyLayout" */ '../features/dashboard/components/layout/LazyLayout'));

export const routes = [
    {
        path: '/dashboard/*',
        to: '/dashboard/',
        Component: LazyLayout,
        name: 'Dashboard - Dash'
    },
    {
        to: '/no-lazy',
        path: 'no-lazy',
        Component: NoLazy,
        name: 'No Lazy'
    },

];
