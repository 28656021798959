
import axios from "axios";



const getDepartments = () => {
    return axios
        .get(`${process.env.REACT_APP_WELLI_API}/api/general/departamentos`)

}


const getMunicipalitiesByDepartment = (codeMunicipality = '') => {
    return axios
        .get(`${process.env.REACT_APP_WELLI_API}/api/general/departamento/${codeMunicipality}/municipios`)
}


export { getDepartments , getMunicipalitiesByDepartment}
