import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import LayoutCreateAccount from "./layout/LayoutCreateAccount";
import { useAuth } from "./hooks/useAuth";
import SessionTimeout from "./Components/Session";
import { useSelector } from "react-redux";
import { selectUser } from "./store/auth";

const ProtectedRoutes = ({createProfile}) => {
  const { isAuthenticated, loginStatus, logOut } = useAuth();
  const user = useSelector(selectUser);
  if (!isAuthenticated === "un-autenticated") {
    return <Navigate to="/create_account" />;
  }

  if (loginStatus == "un-authenticated") {
    return <Navigate to="/newlogin" />;
  }

  if (!createProfile && !user && loginStatus == "authenticated") {
    logOut();
  }

  const handleClick = () => {
    logOut();
  };

  return createProfile ? (
    <LayoutCreateAccount>
      <SessionTimeout isAuthenticated={isAuthenticated} logOut={handleClick} />
      <Outlet />{" "}
    </LayoutCreateAccount>
  ) : (
    <div>
      <SessionTimeout isAuthenticated={isAuthenticated} logOut={handleClick} />
      <Outlet />
    </div>
  );
};

export default ProtectedRoutes;
