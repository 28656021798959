import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getLoan } from "../api/dashboard";

export const fetchLoan = createAsyncThunk("loan/fetchByLoan", async (loan) => {
  const response = await getLoan(loan);
  let bills = [];
  let currentBill = {};
  if (response.data.data.facturas) {
    const allPays = response.data.data.pagos.length > 0 ? response.data.data.pagos : [];

    bills = response.data.data.facturas.map((r, i) => {
      const pays = allPays.filter((p) => p.corte == r.corte );
      let status = "Por pagar";
      if (r.estado.toLowerCase() != "normal") {
        status = "Vencido";
      } else if (r.estado.toLowerCase() == "normal" && pays.length > 0) {
        status = "Pagado";
      }

      return {
        ...r,
        coute: ` Cuota ${i + 1}`,
        status: status,
        pays: pays,
        date: r.fecha_sugerida_pago,
        value: r.total_a_facturar,
      };
    });
    /* get last position array */
    currentBill = bills?.filter((b) => !b.pays?.length)[0] || bills[bills.length - 1];

  }
  return { ...response.data.data, facturas: bills, currentBill };
});

const initialState = {
  loan: [],
  bills: [],
  currentBill: {},
  pays: [],
  loading: "idle", //'idle' | 'pending' | 'succeeded' | 'failed'
  error: "",
};

const loanSlice = createSlice({
  name: "loan",
  initialState,
  reducers: {
    resetLoan: (state) => {
      state.loan = [];
      state.bills = [];
      state.pays = [];
      state.currentBill = {};
      state.loading = "idle";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoan.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchLoan.fulfilled, (state, { payload }) => {
        return {
          loading: "succeded",
          loan: payload.credito,
          bills: payload.facturas,
          pays: payload.pagos,
          currentBill: payload.currentBill,
        };
      })
      .addCase(fetchLoan.rejected, (state, action) => {
        state.loan = [];
        state.bills = [];
        state.pays = [];
        state.currentBill = {};
        state.error = action.error.message;
      });
  },
});

export const { resetLoan } = loanSlice.actions;

export default loanSlice.reducer;

export const selectLoan = ({ loanReducer }) => loanReducer.loan;
export const selectBills = ({ loanReducer }) => loanReducer.bills;

export const selectCurrentBill = ({ loanReducer }) => loanReducer.currentBill;
export const selectPays = ({ loanReducer }) => loanReducer.pays;
export const selectLoanError = ({ loanReducer }) => loanReducer.error;
export const selectLoanStatus = ({ loanReducer }) => loanReducer.loading;
