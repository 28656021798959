import React from 'react';


export const NoLazy = () => {
    return (
        <div>
            <h1>No Lazyload component</h1>
        </div>
    )
}
