import React from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Profile from "./Profile";
import App from "./Appform";
import Offer from "./Offer";
import Sign from "./Sign";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useNavigate } from 'react-router-dom';
import Paper from "@mui/material/Paper";
import Header from "./Header";
import Footer from "./Footer";

import { useSelector } from 'react-redux/es/hooks/useSelector'
import store from "../store"
import { selectActiveStep, handleNext } from "../store/applicationStep"
import useApplicationSteps from "../hooks/useApplicationSteps";

import { Typography } from '@mui/material';



const steps = ["Contacto", "Procedimiento", "Oferta", "Firma"];
const theme = createTheme();

export default function Application() {
  useApplicationSteps();
  const navigate = useNavigate();
  const step = useSelector(selectActiveStep);

  const nextStep = () => {
    store.dispatch(handleNext(step))
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Profile formCompleted={nextStep} />;
      case 1:
        return <App formCompleted={nextStep} />;
      case 2:
        return <Offer formCompleted={nextStep} />;
      case 3:
        return <Sign formCompleted={gotoLogin} />;
      case 4:
          gotoLogin();
          break;
      case 9:
        return <Typography
          component="h1"
          variant="h4"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Validando...
        </Typography>;
     
      default:
        throw new Error("Unknown step");
    }
  }

  const gotoLogin = () => {
    setTimeout(() => {
      navigate('/applast');
    }, 100);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Header />
      </Container>

      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          sx={{
            my: { xs: 3, md: 6 },
            p: { xs: 2, md: 3 },
          }}
        >
          <Stepper activeStep={step} sx={{ pt: 3, pb: 5, overflowX:'auto' }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <React.Fragment>
            {getStepContent(step)}
          </React.Fragment>
        </Paper>
        <Footer />
      </Container>
    </ThemeProvider>
  );
}
