import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import axios from "axios";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { currencyFormat } from "../helpers/formaHelper";
import { createContractApi } from "../api/sign";
import GenericModal from "./GenericModal";

export default function Sign(props) {
  const { formCompleted } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [contract, setContract] = useState({});
  const [appId, setAppId] = useState(0);
  const [contractUrl, setContractUrl] = useState("");
  const [contractCreated, setContractCreated] = useState("idle");
  const [showCreateContract, setShowCreateContract] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const profileId = sessionStorage.getItem("profile_id");
    axios
      .get(
        `${process.env.REACT_APP_WELLI_API}/api/applications/risk/${profileId}`
      )
      .then((resp) => {
        const id = resp.data.app.id;
        setContract(resp.data.risk);
        setAppId(id);
        sessionStorage.setItem("idapp", resp.data.app.id);
        sessionStorage.setItem("medico", resp.data.risk.medico);
        sessionStorage.setItem("monto", resp.data.risk.monto);
        setContractUrl(
          `${process.env.REACT_APP_WELLI_API}/api/contract/create/${id}`
        );
      })
      .catch(() => {
       
      });
  }, []);
  const saveDeceval = () => {
    axios
    .get(
      `${process.env.REACT_APP_WELLI_API}/api/deceval/create/${appId}`
    ).finally(()=>{
      alert(
        "El contrato ha sido firmado, será enviado a tú correo electronico."
      );
      navigate("/applast");
    })
  }
  const onSubmit = (data) => {
    axios
      .get(
        `${process.env.REACT_APP_WELLI_API}/api/contract/validate/${appId}/sms/${data.otpsms}`
      )
      .then(() => {
        formCompleted();
        axios
          .get(
            `${process.env.REACT_APP_WELLI_API}/api/contract/signed/${appId}`
          )
          .then(() => {
            saveDeceval();
          })
          .catch(() => {
            alert("EL contrato no ha podido ser enviado");
          });
      })
      .catch(() => {
        alert(
          "Los códigos ingresados no son correctos. Por favor verifícalos y vuélve a ingresarlos."
        );
      });
  };

  const reenviaOtp = () => {
    //const email = sessionStorage.getItem("email");
    axios
      .get(
        `${process.env.REACT_APP_WELLI_API}/api/contract/otp/retry/${appId}/sms`
      )
      .then(() => {
      })
      .catch(() => {
        alert("Los códigos no han sido reenviados correctamente");
      });
  };

  const createContract = async()=>{
    setContractCreated("pending") 
    createContractApi(contractUrl)
    .then(() => {
      setContractCreated("succeded") 
      setShowCreateContract(false)
    })
    .catch(() => {
    setContractCreated("failed")

    });
  }

  return (
    <>
      <React.Fragment>
        <GenericModal show={contractCreated != 'idle' && contractCreated != 'pending'} 
          textButton="Cerrar"
          description={ contractCreated == 'succeded' ? 'Los códigos fueron enviados al correo electrónico y número celular registrados' : 'Ha ocurrido un error al enviar los códigos'}
          confirm={()=>{setContractCreated('idle');}}
        ></GenericModal>
        <Typography variant="h5" color="primary" gutterBottom>
          Detalle de tú crédito y firma tú contrato:
        </Typography>
        <div>
          <p>Monto Solicitado: ${currencyFormat(contract.monto)}</p>
          <p>Plazo en Meses: {contract.cuotas}</p>
          <p>Valor Cuota Mensual: ${currencyFormat(contract.valor_cuota,false)}</p>
          <p>Fecha Primer Pago: {contract.fecha_primer_pago}</p>
        </div>

        <Typography variant="h6" color="primary" gutterBottom>
          Los fondos serán girados:
        </Typography>

        <div>
          <p>Tercero Autorizado : {contract.medico}</p>
          <p>Procedimiento: {contract.procedimiento}</p>
        </div>

        <Typography variant="h6" color="primary" gutterBottom>
          Tasas y cargos incluidos en la cuota mensual
        </Typography>

        <p>Seguro vida Mensual:  ${currencyFormat(parseInt(contract.seguro_vida_mensual))}</p>
        <p>Valor Fianza Mensual FGA + IVA:  ${currencyFormat(parseInt(contract.valor_fga))}</p>
        {/* <p>Tasa de Interés %E.A.: {contract.tasa_interes_ea}</p> */}
        <p>Tasa de Interés M.V.: {(contract.tasa_interes_mv * 100).toFixed(2)} %</p>
        <Grid container justifyContent="">
          <Grid item sx={{marginBottom:'10px'}}>
            <Link
              target="_blank"
              href={ `${process.env.REACT_APP_WELLI_API}/api/contract/review_contract/${appId}`}
              variant="body2"
            >
              Descargar contrato ejemplo
            </Link>
          </Grid>
        </Grid>
        <Grid container justifyContent="">
          <Grid item>
            <Link
              target="_blank"
              href={ `${process.env.REACT_APP_WELLI_API}/api/pagare/review/${appId}`}
              variant="body2"
            >
              Descargar pagaré 
            </Link>
          </Grid>
        </Grid>
        {showCreateContract &&


          <Grid item xs={12}>
            <Button
              disabled={contractCreated === 'pending'}
              onClick={createContract}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {contractCreated === 'pending' ? 'Enviando códigos para firma digital...' : 'Solicite el código para firmar el contrato'}
            </Button>
          </Grid>

        }
      
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <TextField
              name="otpsms"
              fullWidth
              label="Codigo OTP"
              {...register("otpsms", {
                required: "El código otp del celular es requerido requerido",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Ingresa datos numéricos",
                },
                maxLength: {
                  value: 4,
                  message: "El código no debe ser mayor de 4 dígitos",
                },
                minLength: {
                  value: 4,
                  message: "El código no debe ser menor de 4 dígitos",
                },
              })}
              error={Boolean(errors.otpsms)}
              helperText={errors.otpsms?.message}
            />
          </Grid>
       
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="checkContrato"
                  defaultChecked
                />
              }
              label={
                <Typography variant="caption">
                  Acepto Términos del Contrato y Pagaré
                </Typography>
              }
            />
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Firmar contrato con codigos recibidos
          </Button>
        </Box>

        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link
              href="#"
              onClick={() => reenviaOtp()}
              variant="body2"
            >
              ¿No has recibido el código? Enviar Nuevamente
            </Link>
          </Grid>
        </Grid>
      </React.Fragment>
    </>
  );
}
