import * as React from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import logoTemp from "../01_Welli_Logo.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import InputAdornment from "@mui/material/InputAdornment";
import SearchDoctor from "../Shared/SearchDoctor";
import GenericModal from "./GenericModal";
import { useSearchParams } from "react-router-dom";
import Copyright from "../Shared/Copyright";

const EMAIL_PATTERN =  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/


const theme = createTheme();

export default function CreateAccount() {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const [searchParams] = useSearchParams();
  const onSubmit = (data) => {
    data = {
      ...data,
      phone: 57 + data.phone,
    };
    const doctor_name_id = data.doctor_name_id;
    delete data.email2;
    delete data.doctor_name_id;
  
    axios
      .post(`${process.env.REACT_APP_WELLI_API}/api/accounts`, data)
      .then((resp) => {
        sessionStorage.setItem("email", resp.data.email);
        sessionStorage.setItem("celular", resp.data.phone);
        
        if(sessionStorage.getItem('registerByUrl')){
          const currentData = JSON.parse(sessionStorage.getItem('registerByUrl'));
          if (doctor_name_id) {
            currentData.medicoId = doctor_name_id;
            currentData.medico = data.doctor_name;
          }else{
            currentData.medicoId = null;
            currentData.medico = null;
          }
          sessionStorage.setItem(
            "registerByUrl",
            JSON.stringify(currentData) 
          );
        }
        navigate("/val_account");
      })
      .catch((error) => {
        console.log("error", error);
        setShowModal(true);
      });
  };
  useEffect(() => {
    const medico = searchParams.get("nm");
    const medicoId = searchParams.get("mi");
    const pattientPhone = searchParams.get("ph");
    const pattientName = searchParams.get("pn");
    const pattientLastName = searchParams.get("pln");

    if (medicoId) {
      setValue('doctor_name', medico);
      sessionStorage.setItem(
        "registerByUrl",
        JSON.stringify({
          medico,
          medicoId,
          pattientPhone,
          pattientName,
          pattientLastName,
        })
      );
     
    }
  }, []);

  function BoldText({ children }) {
    return <span style={{ fontWeight: "bold" }}>{children}</span>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <GenericModal
          show={showModal}
          textButton="Cerrar"
          confirm={() => {
            setShowModal(false);
          }}
          title="Usuario creado previamente"
          description="Tu información ya esta registrada con Welli. Haz clic en el botón de INICIAR SESION para acceder a tu cuenta"
        />
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="140"
                image={logoTemp}
                alt="Logo"
              />
              <CardContent>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="justify"
                >
                  Ofrecemos planes de <BoldText>financiación</BoldText> simples
                  y transparentes para que puedas mejorar tú{" "}
                  <BoldText>bienestar</BoldText> con aprobación en{" "}
                  <BoldText>minutos</BoldText>.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="phone"
                  autoComplete="celular"
                  fullWidth
                  label="Celular"
                  autoFocus
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+57</InputAdornment>
                    ),
                  }}
                  {...register("phone", {
                    required: "El número de celular es requerido",
                    pattern: {
                      value: /^3\d{9}$/,
                      message: "El número de celular no es válido",
                    },
                    maxLength: {
                      value: 10,
                      message:
                        "El número de celular no debe ser mayor de 10 caracteres",
                    },
                    minLength: {
                      value: 10,
                      message:
                        "El número de celular no debe ser menor de 10 caracteres",
                    },
                  })}
                  error={Boolean(errors.phone)}
                  helperText={errors.phone?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Correo Electrónico"
                  name="email"
                  autoComplete="email"
                  {...register("email", {
                    required: "El correo electrónico es requerido",
                    pattern: {
                      value:EMAIL_PATTERN,
                      message:
                        "Ingresa un formato válido de correo electrónico",
                    },
                    onChange: (event) => {
                      setValue(
                        "email",
                        event.target.value.toLowerCase().replace(/\s+/g, "")
                      );
                    },
                  })}
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Confirmar Correo Electrónico"
                  name="email2"
                  onPaste={(e) => e.preventDefault()}
                  autoComplete="email"
                  {...register("email2", {
                    required: "Debes confirmar el correo electrónico",
                    pattern: {
                      value:EMAIL_PATTERN,
                      message:
                        "Ingresa un formato válido de correo electrónico",
                    },
                    onChange: (event) => {
                      setValue(
                        "email2",
                        event.target.value.toLowerCase().replace(/\s+/g, "")
                      );
                    },
                    validate: (value) =>
                      value === watch("email") ||
                      "Los correos electrónicos no coinciden",
                  })}
                  error={Boolean(errors.email2)}
                  helperText={errors.email2?.message}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SearchDoctor
                  updateValue={setValue}
                  setInputId
                  initialValue={searchParams.get("nm")}
                  inputName={"doctor_name"}
                  inputIdName={"doctor_name_id"}
                  register={register}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox required={true} />}
                  label={
                    <Typography variant="caption">
                      <Link
                        href="/assets/documents/welli.pdf"
                        target="_blank"
                        rel="noopener"
                      >
                        Acepto términos y condiciones.
                      </Link>
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox required={true} color="primary" />}
                  label={
                    <Typography variant="caption">
                      <Link
                        href="https://storage.googleapis.com/welli-static/documents/politica-tratamiento-datos-personales.pdf"
                        target="_blank"
                        rel="noopener"
                      >
                        Autorizo el uso de datos personales según nuestra
                        política de privacidad
                      </Link>
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Comenzar Solicitud
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link component={RouterLink} to="/newlogin" variant="body2">
                  ¿Tienes cuenta Welli? Inicia Sesión
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="https://wa.me/message/QV6QPGYTOXOFC1"
                  variant="body2"
                  target="_blank"
                  rel="noopener"
                >
                  Envía un mensaje a Welli Colombia por WhatsApp
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
