import { useEffect, useState } from "react";
import { useDebounced } from "./useDebounced";
import { searchDoctors } from "../api/doctors";

export const useDoctorDebounced = (input) => {
    const debouncedValue = useDebounced(input, 800)
    const [loadingDoctors, setLoadingDoctors] = useState(false);
    const [options, setOptions] = useState([]);


    useEffect(() => {

        const getDoctors = async () => {

            if (input) {
                setOptions([])
                setLoadingDoctors(true)

                const resp = await searchDoctors(input);
                const data = resp.data.data
                data.length ? setOptions(data) : setOptions([]);
                setLoadingDoctors(false)
            }

        }
        getDoctors();

    }, [debouncedValue])

    return {loadingDoctors, options}
}