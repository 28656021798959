
import axios from "axios";

const createContractApi = (path) => {
    return axios.get(path)

}
const getProfileApi = (profileId) => {
    return axios.get(`${process.env.REACT_APP_WELLI_API}/api/profile/${profileId}`);
}
export { createContractApi, getProfileApi }
