import React from 'react';
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import templogo from "../Welli_Logo-06.jpg";

export default function Header() {

  function BoldText({ children }) {
    return (
      <span style={{ fontWeight: 'bold' }}>{children}</span>
    );
  }

  return (
    <Paper
      sx={{
        my: { xs: 3, md: 6 },
        p: { xs: 2, md: 3 },
        marginTop: 9,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img alt="Welli" src={templogo} width="105" />
      <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2 }}>
        Queremos <BoldText>conocerte</BoldText> para determinar un plan de <BoldText>financiación</BoldText> que se
        ajuste a tus <BoldText>necesidades</BoldText>
      </Typography>
    </Paper>
  );
}
