import { useEffect, useState } from "react"
import { getProfileApi } from "../api/sign"



export const useProfile = () => {
    const profileId = sessionStorage.getItem('profile_id')
    const  [profile, setProfile] = useState("")

    useEffect(() => {
        const getProfile = async()=>{
            console.log('heree');
            const resp = await getProfileApi(profileId)
            resp.data && setProfile(resp.data)

        }
        if (profileId) {
            getProfile()
        }
    }, [])

    return {profile}

}