import { createSlice } from '@reduxjs/toolkit'

export const apllicationStepSlice = createSlice({
  name: 'apllicationStep',
  initialState: {
    step: 9,
  },
  reducers: {
    handleNext: (state) => {
      state.step += 1
    },

    handleBack: (state) => {
      state.step -= 1
    },
    incrementByAmount: (state, action) => {
      state.step = action.payload
    },

    resetApllicationStep: (state) => {
      state.step = 9
    }
  },
})

// Action creators are generated for each case reducer function
export const { handleNext, handleBack, incrementByAmount, resetApllicationStep } = apllicationStepSlice.actions

export const selectActiveStep = (state) => state.apllicationStep.step

export default apllicationStepSlice.reducer