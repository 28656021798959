import { createSlice } from '@reduxjs/toolkit'



const initialState = {
  user: null,
  token: null,
  fetching: 'idle', //'idle' | 'pending' | 'succeeded' | 'failed'
  status: 'iddle', //'un-authenticated', // 'authenticated', checking
  error: ''
}


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuth: (state) => ({
      ...state,
      user  : null,
      fetching : 'idle',
      status : 'un-authenticated',
      token : null,
      error:''
    }),
    setAuth: (state, { payload }) => (
      {
        ...state,
        user: payload.user,
        token: payload.token,
        status: payload.status
      }
    ),
    setStatus: (state, { payload }) => (
      {
        ...state,
        status: payload.status
      }
    ),
  },
})


export const { resetAuth , setAuth } = authSlice.actions
export default authSlice.reducer

export const selectUser = (state) => state.auth.user
export const selectToken = (state) => state.auth.token
export const selectStatus = (state) => state.auth.status
export const selectUserError = (state) => state.user.error
export const selectUserFetching = (state) => state.user.loading
