import React, { useEffect } from 'react'
import { useDoctorDebounced } from '../hooks/useDoctorDebounced';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
//updateValue / setValue
const SearchDoctor = ({ updateValue, initialValue=null, register, errors, inputName, label = 'Profesional Médico', errorLabel = "El nombre del profesional es requerido", setInputId = false , inputIdName='medico_id' }) => {
    const [inputDoctor, setInput] = React.useState('');
    const { options, loadingDoctors } = useDoctorDebounced(inputDoctor)

    useEffect(()=>{
        if (initialValue) {
            setInput(initialValue);
        }
    },[])

    return (
        <Autocomplete
            options={options}
            inputValue={inputDoctor}
            loading={loadingDoctors}
            loadingText={'Buscando...'}
            freeSolo
            getOptionLabel={(option) => option.nombre_comercial}
            key={option => option.id}
            onInputChange={(event, value) => {
                if (setInputId) {
                    updateValue(inputIdName,'');
                }
                setInput(value);
                updateValue(inputName, value, { shouldValidate: true });
            }}

            onChange={(event, value) => {
                if (setInputId) {
                    if (value) {
                        updateValue(inputIdName, value.id);
                    } else {
                        updateValue(inputIdName,'');
                    }
                }

            }}

            filterOptions={(x) => x}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loadingDoctors ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    inputRef={register(inputName, { required: errorLabel, })}
                    error={Boolean(errors[inputName])}
                    helperText={errors[inputName]?.message}

                />

            )}

        />
    )
}

export default SearchDoctor