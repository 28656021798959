
export const REJECTED = "rejected";
export const DISMISSED = "dismissed";
export const RISK_IN_PROCESS = "risk_in_process"
export const ON_HOLD_DOCS = "on_hold_docs"
export const APPROVED = "approved"
export const SIGN_CONTRACT = "firma_contrato"
export const PENDIENTE_VALIDACION_CLIENTE = "pendiente_validacion_cliente"
export const PENDIENTE_APROBACION_MEDICO ="pendiente_aprobacion_medico"
export const PENDIENTE_DESEMBOLSO = "pendiente_desembolso"
export const DESEMBOLSADO = "desembolsado"

export const  availableAccessDashboardStatus = [DESEMBOLSADO]
export const  validatingAPPStatus = [RISK_IN_PROCESS, PENDIENTE_VALIDACION_CLIENTE, PENDIENTE_APROBACION_MEDICO , PENDIENTE_DESEMBOLSO]
export const  unavailableAnyAccess = [REJECTED, DISMISSED]