import React, { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import SessionTimeoutDialog from './SessionTimeoutDialog'
let countdownInterval;
let timeout;
export default function SessionTimeout({ isAuthenticated, logOut }) {
  const [, setState] = useState('Active')
  const [count, setCount] = useState(0)
  const [, setRemaining] = useState(0)
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);

  const onIdle = () => {
    setState('Idle')
    const delay = 100 * 1;
    if (isAuthenticated && !timeoutModalOpen) {
      timeout = setTimeout(() => {
        let countDown = 15;
        setTimeoutModalOpen(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown);
          } else {
            handleLogout(true);
          }
        }, 1000);
      }, delay);
    }
  }
  const onActive = () => {
    setState('Active')
  }

  const onAction = () => {
    setCount(count + 1)
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 120000,
    throttle: 1000
  })

  useEffect(() => {
    const interval = setInterval(() => {

      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  })
  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };
  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  const handleContinue = () => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
  };

  const handleLogout = async (isTimedOut = false) => {
    try {
      setTimeoutModalOpen(isTimedOut);
      clearSessionInterval();
      clearSessionTimeout();
      logOut();
    } catch (err) {
      console.error(err);
    }
  };


  return (
    <SessionTimeoutDialog
      countdown={timeoutCountdown}
      onContinue={handleContinue}
      onLogout={() => handleLogout(false)}
      open={timeoutModalOpen}
    />
  )
}
