/* eslint-disable no-unreachable */
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import InProcessModal from "./InProcessModal";
import { NumericFormatCustom } from "../Shared/NumericFormatCustom";
import { Autocomplete, CircularProgress } from "@mui/material";
import { useDebounced } from "../hooks/useDebounced";
import { MAX_AMOUNT_VALUE, MIN_AMOUNT_VALUE } from "../core/enums/credit";
import { currencyFormat } from "../helpers/formaHelper";
import GenericModal from "./GenericModal";
import { useAuth } from "../hooks/useAuth";
import BodyRejectedComponent from "../Shared/BodyRejectedComponent";
import { useProceduresDebounced } from "../hooks/useProcedureDebounced";

const cuotasDefault = 36;

export default function App(props) {
  const { formCompleted } = props;
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    
  } = useForm({defaultValues: {
   
  }});
  const [laodAmor, setloadAmor] = useState([]);
  const [showModalProcess, setShowModalProcess] = useState(false);
  const [loadingDoctors, setLoadingDoctors] = useState(false);
  const [showRejectedModal, setShowRejectedModal] = useState(false);
  const [registerByUrl, setRegisterByUrl] = useState(false);

  const [options, setOptions] = useState([]);
  const [input, setInput] = useState("");
  const [inputProcedure, setInputProcedures] = useState("");
  const debouncedValue = useDebounced(input, 800);
  const { loadingProcedures, options: optionsProcedures } =
    useProceduresDebounced(inputProcedure);
  const { logOut } = useAuth();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_WELLI_API}/api/general/amortization/6666666/36`
      )
      .then((resp) => {
        setloadAmor(resp.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  useEffect(() => {
    const  registerByUrl = sessionStorage.getItem("registerByUrl") ? JSON.parse(
      sessionStorage.getItem("registerByUrl")) : null;
    if (registerByUrl && registerByUrl.medicoId) {
      setTimeout(() => {
        
        setRegisterByUrl(registerByUrl);
        setInput(registerByUrl.medico);
      }, 900);
    }
  }, []);

  useEffect(() => {
    const getDoctors = async () => {
      if (input) {
        setLoadingDoctors(true);
        const uri = `${
          process.env.REACT_APP_WELLI_API
        }/api/doctors/search?nombre=${encodeURIComponent(input)}`;
        const resp = await axios.get(uri);
        const data = resp.data.data;
        data.length ? setOptions(data) : setOptions([]);
        if (registerByUrl, registerByUrl.medicoId) {
          const medicoSearch = resp.data.data.find(
            (m) => m.id === registerByUrl.medicoId
          );
          if (medicoSearch) {
            setValue("medico_id", registerByUrl.medicoId);
            setValue("medico", medicoSearch.nombre_comercial);
          }
        }
        setLoadingDoctors(false);
      }
    };
    getDoctors();
  }, [debouncedValue]);

  const onSubmit = (data) => {
    const profile_id = sessionStorage.getItem("profile_id");
    setShowModalProcess(true);

    const body = {
      ...data,
      fecha_procedimiento: dayjs(data.fecha_procedimiento).format("YYYY-MM-DD"),
      profile_id: profile_id,
      amortizacion: JSON.stringify(laodAmor),
      cuotas: parseInt(cuotasDefault),
    };

    const url = `${process.env.REACT_APP_WELLI_API}/api/applications/add`;

    axios
      .post(url, body)
      .then((res) => {
        if(res.data.estado ==='rejected'){
          setShowRejectedModal(true);
          return
        }
        formCompleted();
      })
      .catch(function (error) {
        console.log("error", error);
      }); 
  };
  const notValidatedApplication = () => {
    logOut();
  };
  return (
    <>
      <React.Fragment>
        <GenericModal
          show={showRejectedModal}
          title="  No pudimos aprobar tu crédito."
          description=""
          confirm={notValidatedApplication}
          bodyComponent={<BodyRejectedComponent />}
        ></GenericModal>
        <InProcessModal show={showModalProcess}></InProcessModal>
        <Typography variant="h6" align="center" gutterBottom>
          Información del procedimiento
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                options={options}
                inputValue={input}
                loading={loadingDoctors}
                loadingText={"Buscando..."}
                filterOptions={(x) => x}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.nombre_comercial}
                key={(option) => option.id}
                onInputChange={(event, value) => {
                  if (!registerByUrl) {
                    setInput(value);
                  }
                }}
                onChange={(event, value) => {
                  if (value) {
                    setValue("medico_id", value.id);
                    setValue("medico", value.nombre_comercial);
                  } else {
                    setValue("medico_id", "");
                    setValue("medico", "");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Profesional Médico o Institución"
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingDoctors ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    inputRef={register("medico", { required: true })}
                    error={Boolean(errors.medico)}
                    helperText={errors.medico ? "Este campo es requerido" : ""}
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.nombre_comercial}
                    </li>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                options={optionsProcedures}
                inputValue={inputProcedure}
                loading={loadingProcedures}
                loadingText={"Buscando..."}
                filterOptions={(x) => x}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => `${option.name}`}
                key={(option) => option.id}
                onInputChange={(event, value) => {
                  setInputProcedures(value);
                }}
                onChange={(event, value) => {
                  if (value) {
                    setValue("procedimiento", `${value.speciality_name } - ${value.name}`);
                  } else {
                    setValue("procedimiento", "");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Procedimiento"
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingProcedures ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    inputRef={register("procedimiento", { required: true })}
                    error={Boolean(errors.procedimiento)}
                    helperText={
                      errors.procedimiento ? "El procedimiento es requerido" : ""
                    }
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.speciality_name } - {option.name}
                    </li>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="monto"
                control={control}
                defaultValue=""
                fullWidth
                rules={{
                  required: true,
                  min: MIN_AMOUNT_VALUE,
                  max: MAX_AMOUNT_VALUE,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Cuánto dinero necesitas?"
                    variant="standard"
                    fullWidth
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                    error={Boolean(errors.monto)}
                  />
                )}
                error={Boolean(errors.monto)}
                helperText={errors.monto?.message}
              />
              {errors.monto && (
                <Typography variant="caption" color="error">
                  {errors.monto.type === "required" &&
                    "El costo estimado es requerido"}
                  {errors.monto.type === "max" &&
                    `El costo no puede superar los ${currencyFormat(
                      MAX_AMOUNT_VALUE
                    )}`}
                  {errors.monto.type === "min" &&
                    `El costo no puede ser inferior a los ${currencyFormat(
                      MIN_AMOUNT_VALUE
                    )}`}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid marginTop="40px" container justifyContent="flex-end">
            <Grid item>
              <Typography variant="h6" align="center" gutterBottom>
                Al hacer clic en “Siguiente” no actualices la página, espera
                mientras evaluamos tu aplicación
              </Typography>
            </Grid>
          </Grid>

          <Box
            marginTop="10px"
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button type="submit" variant="contained" sx={{ mt: 3, ml: 1 }}>
              Siguiente
            </Button>
          </Box>
        </Box>
      </React.Fragment>
    </>
  );
}
