import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import logoTemp from "../01_Welli_Logo.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MuiOtpInput } from "mui-one-time-password-input";
import store from "../store"
import { setAuth } from "../store/auth";
import Copyright from "../Shared/Copyright";


const theme = createTheme();

export default function ValidationAccount() {
  const {
    handleSubmit,
  
  } = useForm();
  const navigate = useNavigate();

  const [value, setValue] = React.useState("");

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const onSubmit = (data) => {
    const url =
      `${process.env.REACT_APP_WELLI_API}/api/accounts/confirm/email/` +
      sessionStorage.getItem("email") +
      "/" +
      value;

    axios
      .post(url, data)
      .then((resp) => {
        sessionStorage.setItem("token", resp.data.token);
        sessionStorage.setItem("id", resp.data.id);
        store.dispatch(setAuth({user:true, token: resp.data.token , status:'authenticated'}))

        navigate("/profileform");
      })
      .catch((error) => {
        console.log("error", error);
        alert(
          "El código ingresado no es correcto. Por favor verifícalo y vuélvelo a ingresar."
        );
      });
  };

  const reenviaOtp = () => {
    const email = sessionStorage.getItem("email");
    axios
      .get(`${process.env.REACT_APP_WELLI_API}/api/accounts/retry/${email}`)
      .then((resp) => {
        alert("Hemos enviado nuevamente el codigo, si es necesario revisa el spam de tu bandeja de entrada")
        console.log(resp);
      })
      .catch((error) => {
        console.log("error", error);
        alert("Registra porfavor nuevamente el correo electrónico")
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="140"
                image={logoTemp}
                alt="Logo"
              />
              <CardContent>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="justify"
                >
                  Ingresa acá el código de 4 digitos enviado a tu celular y
                  correo electrónico.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
               
                <MuiOtpInput length={4} value={value} onChange={handleChange} />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Validar y Continuar
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="#" onClick={() => reenviaOtp()} variant="body2">
                  ¿No has recibido el código? Enviar Nuevamente
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
